<template>
  <v-container>
    <!-- <v-row> -->
    <v-col
      v-if="displaySidebar"
      align-self="center"
    >
      <v-navigation-drawer
        v-model="drawer"
        :mini-variant.sync="mini"
        permanent
        height="auto"
        style="position : fixed; margin-top: 85px; z-index: 500;"
      >
        <v-list-item class="px-2">
          <v-list-item-avatar>
            <v-icon>mdi-dev-to</v-icon>
          </v-list-item-avatar>

          <v-list-item-title> Paydone docs  </v-list-item-title>

          <v-btn
            icon
            @click.stop="mini = !mini"
          >
            <v-icon>mdi-chevron-left</v-icon>
          </v-btn>
        </v-list-item>
        <v-divider />
        <v-list>
          <div
            v-for="parent in items"
            :key="parent.title"
          >
            <!-- Parent Level -->
            <v-list-group
              v-if="parent.children"
              color="secondary"
              :disable-resize-watcher="false"
            >
              <!-- Parent List group -->
              <template #activator>
                <v-list-item-icon>
                  <v-icon>{{ parent.icon }}</v-icon>
                </v-list-item-icon>
                <v-list-item-title>{{ parent.title }}</v-list-item-title>
              </template>
              <div
                v-for="child in parent.children"
                :key="child.title"
              >
                <v-list-group
                  v-if="child.children"
                  color="secondary"
                  :disable-resize-watcher="false"
                  sub-group
                >
                  <!-- Child List group -->
                  <template #activator>
                    <v-list-item-title>{{ child.title }}</v-list-item-title>
                  </template>
                  <div
                    v-for="kid in child.children"
                    :key="kid.title"
                  >
                    <v-list-item
                      :href="`#${kid.name}`"
                      link
                    >
                      <v-list-item-content>
                        <v-list-item-title>{{ kid.title }}</v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </div>
                </v-list-group>
                <!-- Child List Item -->
                <v-list-item
                  v-else
                  :href="`#${child.name}`"
                  link
                >
                  <v-list-item-icon>
                    <v-icon>{{ child.icon }}</v-icon>
                  </v-list-item-icon>

                  <v-list-item-content>
                    <v-list-item-title>{{ child.title }}</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </div>
            </v-list-group>
            <!-- Level Item -->
            <v-list-item
              v-else
              :href="(parent.href ? parent.href :`#${parent.name}`)"
            >
              <v-list-item-icon>
                <v-icon>{{ parent.icon }}</v-icon>
              </v-list-item-icon>

              <v-list-item-content>
                <v-list-item-title>{{ parent.title }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </div>
        </v-list>
      </v-navigation-drawer>
    </v-col>
    <v-col>
      <v-scroll-y-transition mode="out-in">
        <div>
          <v-toolbar
            color="secondary"
            dark
          >
            <v-toolbar-title
              class="d-xs-none d-sm-flex"
            >
              {{ $t('devs.content.title') }}
            </v-toolbar-title>
            <v-spacer />

            <v-btn
              v-for="lang in content.langs"
              :key="lang.name"
              icon
              :class="{activelang:lang.active}"
              @click="selecteLang(lang)"
            >
              <v-icon>mdi-{{ lang.icon }}</v-icon>
            </v-btn>
          </v-toolbar>
          <v-card
            v-for="card in content.cards"
            :id="card.name"
            :key="card.name"
            class="pt-6 mt-2"
            outlined
          >
            <v-card-text>
              <h3 class="text-h5 mb-2">
                {{ card.title }}
              </h3>
              <div
                v-for="bloc in card.blocs"
                :key="bloc.name"
              >
                <div
                  :id="bloc.name"
                  class="blue--text mb-2"
                >
                  {{ bloc.header }}
                </div>
                <p
                  v-for="(parag,i) in bloc.parags"
                  :key="i"
                >
                  {{ parag }}
                </p>
                <v-badge
                  v-for="badge in bloc.badges"
                  :key="badge.title"
                  bordered
                  :color="badge.color"
                  :icon="badge.icon"
                  overlap
                >
                  <v-alert
                    shaped
                    outlined
                    :color="badge.color"
                  >
                    <strong>{{ badge.title }} :</strong> {{ badge.text }}
                  </v-alert>
                </v-badge>
                <vue-code-highlight
                  v-if="bloc.codelines"
                  language="javascript"
                  theme="prisma-dark"
                >
                  <pre>
                <div
v-for="(line,i) in bloc.codelines"
:key="i"
>
{{ line }}
</div>
                </pre>
                </vue-code-highlight>
                <v-list v-if="bloc.list">
                  <v-subheader v-if="bloc.list.header">
                    {{ bloc.list.header }}
                  </v-subheader>
                  <v-list-item-group>
                    <v-list-item
                      v-for="(item, i) in bloc.list.items"
                      :key="i"
                      :href="`${item.href}`"
                      :disabled="item.href?false:true"
                    >
                      <v-list-item-icon>
                        <v-icon
                          color="secondary"
                          v-text="item.icon"
                        />
                      </v-list-item-icon>
                      <v-list-item-content>
                        <v-list-item-title v-text="item.title" />
                        <v-list-item-subtitle v-text="item.desc" />
                      </v-list-item-content>
                    </v-list-item>
                  </v-list-item-group>
                </v-list>
                <v-alert
                  v-if="bloc.alert"
                  :type="bloc.alert.type"
                  :color="bloc.alert.color"
                >
                  <p
                    v-for="(p,i) in bloc.alert.parags"
                    :key="i"
                  >
                    {{ p }}
                  </p>
                </v-alert>
              </div>
            </v-card-text>
            <v-divider />
          </v-card>
        </div>
      </v-scroll-y-transition>
    </v-col>
    <!-- </v-row> -->
  </v-container>
</template>

<script>
  import { component as VueCodeHighlight } from 'vue-code-highlight'

  export default {
    name: 'DevsOverview',

    components: {
      VueCodeHighlight,
    },

    data: () => ({
      selected: {
        title: 'Get Strated',
        icon: 'mdi-clock-start',
        name: 'getStarted',
      },
      selectedLang: {
        name: 'NodeJS',
        icon: 'nodejs',
      },
      drawer: true,
      mini: true,
      langs: [
        { name: 'NodeJS', icon: 'nodejs', active: true },
        // { name: 'Python', icon: 'language-python', active: false },
        // { name: 'PHP', icon: 'language-php', active: false },
        // { name: '.Net', icon: 'dot-net', active: false },
        // { name: 'GO', icon: 'language-go', active: false },
        // { name: 'Swift', icon: 'language-swift', active: false },
        // { name: 'Java', icon: 'language-java', active: false },
        // { name: 'Kotlin', icon: 'language-kotlin', active: false },
      ],
    }),
    computed: {
      curlang: function (lang) {
        return {
          activelang: this.selectedLang.name === lang.name,
        }
      },
      displaySidebar: function () {
        var value = false
        switch (this.$vuetify.breakpoint.name) {
          case 'xs': value = false
                     break
          case 'sm': value = false
                     break
          case 'md': value = true
                     break
          case 'lg': value = true
                     break
          case 'xl': value = true
        }
        // console.log('value', this.$vuetify.breakpoint.name)
        return value
      },
      items: function () {
        return this.$t('devs.items')
      },
      content: function () {
        return this.$t('devs.content')
      },
    },
    mounted () {
      // console.log(this.items)
    },
    methods: {
      itemSelected (item) {
        this.selected = item
      },
      selecteLang (lang) {
        this.selectedLang = lang
        const l = this.content.langs.find(element => element.name === lang.name)
        this.content.langs.forEach((l) => {
          if (l.name === lang.name) {
            l.active = true
          } else {
            l.active = false
          }
        })
        l.active = true
      },
    },
  }
</script>
<style scoped>
/* @import '../../styles/prisma/prism.css'; */
@import '../../assets/prism-okaidia.css';
.activelang{
  border: #16ecf3;
  border-style: solid
}
code{
  background-color: transparent;
}
</style>
